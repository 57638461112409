<template>
  <nav class="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
    <div class="container-fluid">
      <router-link to="/" class="navbar-brand" @click.native="collapseNavbar">Thumbscore Demo Dashboard</router-link>
      <button
        :class="['navbar-toggler', !navbarExpanded && 'collapsed']"
        type="button"
        aria-controls="navbarDefault"
        :aria-expanded="navbarExpanded"
        aria-label="Toggle navigation"
        @click="toggleNavbar"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div id="navbarDefault" :class="['collapse navbar-collapse justify-content-end', navbarExpanded && 'show']">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/" exact exact-active-class="active" class="nav-link" @click.native="collapseNavbar"
              >Observations</router-link
            >
          </li>
          <li class="nav-item">
            <router-link to="/insights" class="nav-link" @click.native="collapseNavbar">Insights</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/concerns" class="nav-link" @click.native="collapseNavbar">Concerns</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      navbarExpanded: false
    }
  },
  methods: {
    toggleNavbar() {
      this.navbarExpanded = !this.navbarExpanded
    },
    collapseNavbar() {
      this.navbarExpanded = false
    }
  }
}
</script>
<style scoped>
.container-fluid.navbar-container,
.navbar-brand {
  padding-left: 10;
}
</style>
