<template>
  <div>
    <div v-if="!isMobileComputed">
      <div id="home_screen" class="container-fluid main_container">
        <div class="row row-cols-2">
          <div class="col">
            <apexchart
              type="donut"
              :height="grabChartHeight + 32"
              :options="donutChartOptions"
              :series="seriesQuad"
            ></apexchart>
          </div>
          <div class="col">
            <apexchart type="bar" :height="grabChartHeight" :options="chartOptions" :series="seriesThumbscores"></apexchart>
          </div>
          <div class="col">
            <apexchart type="bar" :height="grabChartHeight" :options="viewsChartOptions" :series="seriesViews"></apexchart>
          </div>
          <div class="col">
            <apexchart
              type="bar"
              :height="grabChartHeight"
              :options="literacyChartOptions"
              :series="seriesLiteracy"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMobileComputed">
      <div id="home_screen" class="container-fluid main_container">
        <div class="row">
          <div class="col">
            <apexchart type="donut" :height="grabChartHeight" :options="donutChartOptions" :series="seriesQuad"></apexchart>
            <p></p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <apexchart type="bar" :height="grabChartHeight" :options="chartOptions" :series="seriesThumbscores"></apexchart>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <apexchart type="bar" :height="grabChartHeight" :options="viewsChartOptions" :series="seriesViews"></apexchart>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <apexchart
              type="bar"
              :height="grabChartHeight"
              :options="literacyChartOptions"
              :series="seriesLiteracy"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'

Vue.component('Apexchart', VueApexCharts)

export default {
  name: 'Home',
  data() {
    return {
      isMobile: false,

      literacyChartOptions: {
        theme: {
          mode: 'dark',
          palette: 'palette1'
        },
        chart: {
          background: '#16161D'
        },
        dataLabels: {
          dropShadow: {
            enabled: true
          },
          formatter: function (val) {
            const percentValue = val * 100

            return val ? percentValue.toFixed(0) + '%' : ''
          }
        },
        tooltip: {
          y: {
            formatter: function (value, opts) {
              const percent = value * 100

              return percent.toFixed(0) + '%'
            }
          }
        },
        title: {
          text: 'Financial Literacy',
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#fff'
          }
        },
        xaxis: {
          categories: [
            'Voluntary Benefits',
            'Disability Coverage',
            'Taxes Basics',
            'Life Insurance Basics',
            'Investment Basics',
            'Credit Basics',
            'Medical Checkups'
          ]
        }
      },
      seriesLiteracy: [
        {
          name: 'level',
          data: [0.39, 0.41, 0.53, 0.57, 0.61, 0.68, 0.7]
        }
      ],

      donutChartOptions: {
        chart: {
          type: 'donut',
          background: '#16161D'
        },
        title: {
          text: 'Financial Stress Quadrant',
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#fff'
          }
        },
        // size: '15%',
        theme: {
          mode: 'dark'
        },

        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4850'],

        labels: ['thriving', 'coping', 'vulnerable', 'stressed'],
        legend: {
          show: true,
          position: 'bottom'
        }
      },
      seriesQuad: [114, 288, 135, 152],
      chartOptions: {
        theme: {
          mode: 'dark',
          palette: 'palette1'
        },
        title: {
          text: 'Thumbscores',
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#fff'
          }
        },
        chart: {
          foreColor: '#ccc',
          toolbar: {
            show: false
          },
          background: '#16161D'
        },

        xaxis: {
          categories: ['debt', 'spending', 'saving', 'future', 'estate', 'protection']
        },

        colors: [
          function ({ value, seriesIndex, w }) {
            if (value < 2.5) {
              return '#FF4850'
            } else {
              return '#008FFB'
            }
          }
        ]
      },
      seriesThumbscores: [
        {
          name: 'scores',
          data: [2.3, 2.9, 3.4, 3.3, 1.9, 4.1]
        }
      ],
      seriesViews: [
        {
          name: 'views',
          data: [2440, 1833, 1552, 1459, 1296, 1238, 1065, 984]
        }
      ],
      viewsChartOptions: {
        theme: {
          mode: 'dark',
          palette: 'palette1'
        },
        title: {
          text: 'Recent',
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#fff'
          }
        },
        chart: {
          foreColor: '#ccc',
          toolbar: {
            show: false
          },
          background: '#16161D'
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true
          }
        },
        xaxis: {
          categories: [
            'inflation',
            'retirement',
            'debt reduction tips',
            'recession',
            'wage stagnation',
            'spending tips',
            'bills',
            'housing tips'
          ]
        }
      }
    }
  },
  computed: {
    // a computed getter
    grabChartHeight() {
      // `this` points to the component instance
      if (window.innerHeight < 500) {
        return window.innerHeight / 1.2
      }

      return window.innerHeight / 2.3
    },
    isMobileComputed() {
      // `this` points to the component instance
      if (window.innerWidth < 768) {
        return true
      } else if (window.innerHeight < 500) {
        return true
      }

      return false
    }
  },
  mounted() {
    if (window.innerWidth < 576) {
      this.isMobile = true
    }
  }
}
/*
window.Apex = {
  chart: {
    foreColor: '#ccc',
    toolbar: {
      show: false
    },
  },
  stroke: {
    width: 3
  },
  dataLabels: {
    enabled: false
  },
  tooltip: {
    theme: 'dark'
  },
  grid: {
    borderColor: "#535A6C",
    xaxis: {
      lines: {
        show: true
      }
    }
  }
}
*/
</script>
<style scoped></style>
